import Vue from 'vue'
import App from './App.vue'
import Router from 'vue-router'
import router from './config/router'
import ElementUI from 'element-ui';
import method from '@/config/api'
import request from '@/config/index'
import VueI18n from 'vue-i18n'
import websocket from '@/commit/websocket'
import pop from '../components/pop.vue';
import 'element-ui/lib/theme-chalk/index.css';
import './style.css'
import './mixin.css'

import '@/config/lib-flexible'

Vue.use(VueI18n);
Vue.use(Router)
Vue.use(ElementUI);

Vue.config.productionTip = false;
Vue.prototype.$method = method;
Vue.prototype.$postRequest = request.post;
Vue.prototype.$getRequest = request.get;
Vue.prototype.$imgurl = request.baseURL;
Vue.prototype.websocket = websocket;
Vue.prototype.emojiMaplist = [
'😉', '😃', '😅', '🤡', '😊', '🤑', '😌', '😍', '👍', '👌', '😷', '😇', '😛', '😂', '😄', '😁',
'🤣', '🙂','🌘', '🌗', '🌖', '🌕', '🌔', '🌓', '🌒', '🌑', '🌚', '🥰', '😘', '🙃', '😆', '😀', 
'😗', '😝','🤓', '😏', '😒', '😎', '😙', '😚', '🤪', '😞', '😔', '🤩', '🤨', '😋', '🧐', '🥳', 
'😟', '😏','😕', '😫', '😤', '😒', '🙁', '😩', '😠', '😡', '🥺', '😞', '😔', '😣', '😢', '🤬', 
'🤯', '😭','😖', '😟', '😳', '🥵', '🥶', '😶‍️', '😱', '😨', '😰', '😥', '😓', '🤗', '🤔', '🤭', 
'🤫', '🤥', '😶', '😐', '😑', '😬', '🙄', '😯', '😦', '😧', '😮', '😲', '🥱', '😴', '🤤', '😪', 
'😮', '‍😵','‍🤐', '🥴', '🤢', '🤮', '🤧', '🤒', '🤕', '🤠', '⭐', '️🌟', '✨', '💫', '💨', '🤲',
'👐', '🙌', '👏', '🤝', '👎', '👊', '✊', '🤛', '🤜', '🤞', '🤟', '🤘', '🤏', '👈', '👉', '👆',
'👇', '️✋', '🤚', '🖐', '️🖖', '👋', '🤙', '💪', '🙏'
], // 表情
Vue.component('pop',pop)

// 发布者订阅 公共bus
export const Bus = new Vue();

let Chinese = require('@/locales/zh-CN.js')
let English = require('@/locales/en-US.js')
let Japan = require('@/locales/ja-JP.js')
let Korea = require('@/locales/ko-KR.js')
let Taiwan = require('@/locales/zh-TW.js')
const i18n = new VueI18n({
	locale: window.localStorage.getItem("selLanguage"), // 默认语言
	messages: { // 引入语言文件
		'zh-cn': Chinese,
		'en-us': English,
		'ja-jp': Japan,
		'ko-kr': Korea,
		'zh-tw': Taiwan
	}
})
Vue.prototype._i18n = i18n


document.title = 'AMOUR'

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
