<template>
    <el-dialog class="popDirect" width="394px" :show-close="false" :visible.sync="popDirect">
        <div class="popBox">
          <img @click="popDirect = false" style="width: 25px;height: 25px;position: absolute;top: 24px;right: 24px;" 
          src="../src/assets/icon/23.png" alt="">
          <p class="tit">{{titName}}</p>
          <input type="text" :placeholder="i18n.othor.input" v-model="inputValue" v-if="type==5">
          <div class="popSet">
              <div class="bgcolor" style="color: #fff;margin-right: 34px;" @click="popBtn">{{i18n.btn.verify}}</div>
              <div style="background: #E4E4E4;" @click="popDirect = false">{{i18n.btn.cancel}}</div>
          </div>
        </div>
    </el-dialog>
</template>
<script>
export default {
    props:{
      titName:{
          type:String,
          default:''
      },
      type:{
          type:Number,
          default:1
      },
    },
  data() {
    //这里存放数据
    return {
        popDirect:false,
        inputValue:''
    };
  },
  //计算属性 类似于data概念
  computed: {
    i18n() {
      return this.$t("message");
    },
    
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    popBtn(){
      if(this.type == 1){
        this.$emit('delectList',1)
      }else if(this.type == 2){
        this.$emit('transList')
      }else if(this.type == 3){
        this.$emit('delPic')
      }else if(this.type == 4){
        this.$emit('buyMer')
      }else if(this.type == 5){
        this.$emit('add',this.inputValue);
        this.inputValue=''
      }
      this.popDirect = false;

    }
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
   
  },
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {
  }, //如果页面有keep-alive缓存功能，激活
  deactivated() {}, //如果页面有keep-alive缓存功能，非激活
};
</script>

<style lang="less" scoped>
  
.popBox{
    width: 394px;
    height: 269px;
    border-radius: 10px;
    background: #FFFFFF;
    position: relative;
    padding-top: 60px;
    box-sizing: border-box;
    .tit{
      font-size: 18px;
      color: #333333;
      text-align: center;
      display: block;
      margin-bottom: 20px;
    }
    input{
      text-align: center;
      height: 30px;
      font-size: 18px;
      margin: 0 auto;
      display: block;
    }
    .popSet{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 60px;
      div{
        width: 120px;
        height: 45px;
        border-radius: 22.5px;
        color: #525252;
        font-size: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
}
</style>