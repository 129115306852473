<template>
    <div class="aboutList">
        <div class="list" v-for="(item,index) in list" :key="index">
            <div class="listLeft" v-if="item.trends">
                <div class="userInfo" v-if="type == 2">
                    <div class="headPic" :style="{borderColor:item.user.level_result?item.user.level_result.color:'rgba(0,0,0,0)'}">
                        <img :src="$imgurl + item.user.avatar" alt="">
                    </div>
                    <div class="userName">
                        <p>{{item.user.nickname}}</p>
                        <p class="time">{{item.create_time}}</p>
                    </div>
                </div>
                <div class="userInfo" v-else>
                    <div class="headPic" :style="{borderColor:item.trends.user.level_result?item.trends.user.level_result.color:'rgba(0,0,0,0)'}">
                        <img :src="$imgurl + item.trends.user.avatar" alt="">
                    </div>
                    <div class="userName">
                        <p>{{item.trends.user.nickname}}</p>
                        <p class="time">{{item.create_time}}</p>
                    </div>
                </div>
                <div class="content text" v-if="type == 1">{{i18n.othor.Liked}}  
                    <span class="color" style="margin: 0 5px;">{{item.trends.user.nickname}}</span>
                    {{i18n.othor.published}}【
                    <!-- <span class="con text" v-html="item.trends.content"></span> -->
                    <span class="con text">{{item.trends.content}}</span>
                    <span>】{{i18n.home.dynamic}}</span>
                </div>
                <div class="content text" v-if="type == 2">{{item.desc}}
                    <span>{{i18n.othor.Liked}}{{i18n.othor.my2}}{{i18n.othor.published}}【</span>
                    <!-- <span class="con text" v-html="item.trends.content"></span> -->
                    <span class="con text">{{item.trends.content}}</span>
                    <span>】{{i18n.home.dynamic}}</span>
                </div>
                <div class="content text" v-if="type == 3">{{i18n.othor.published}}  【
                    <!-- <span class="con text" v-html="item.trends.content"></span>{{i18n.home.dynamic}} -->
                    <span class="con text">{{item.trends.content}}</span>】{{i18n.home.dynamic}}
                    <span class="color" style="margin: 0 5px;">@{{i18n.othor.my}}</span>
                </div>
            </div>
            <div class="listLeft" v-else>
                <div class="userInfo" v-if="type == 3">
                    <div class="headPic" v-if="item.type == 1" :style="{borderColor:item.association.level_result?item.association.level_result.color:'rgba(0,0,0,0)'}">
                        <img :src="$imgurl + item.association.avatar" alt="">
                    </div>
                    <div class="headPic" v-else :style="{borderColor:item.user.level_result?item.user.level_result.color:'rgba(0,0,0,0)'}">
                        <img :src="$imgurl + item.user.avatar" alt="">
                    </div>
                    <div class="userName" v-if="item.type == 1">
                        <p>{{item.association.nickname}}</p>
                        <p class="time">{{item.create_time}}</p>
                    </div>
                    <div class="userName" v-else>
                        <p>{{item.user.nickname}}</p>
                        <p class="time">{{item.create_time}}</p>
                    </div>
                </div>
                <div class="content text" v-if="type == 3 && item.type == 1">
                    <span class="color" style="margin: 0 5px;">@{{i18n.othor.my}}</span>
                </div>
                <div class="content text" v-if="type == 3 && item.type == 2">{{i18n.othor.published}}【  
                    <span class="con text">{{item.trends.content}}</span>】{{i18n.home.dynamic}}
                    <span class="color" style="margin: 0 5px;">@{{i18n.othor.my}}</span>
                </div>
            </div>
            <div class="tipsBtn bgcolor cursor" v-if="type != 1 && item.is_read == 0" @click="promptBtn(item,index)">{{ i18n.btn.prompt }}</div>
        </div>
    </div>
 </template>
   
 <script>
 export default {
   components: {},
   props:{
     type:{
         type:Number,
         default:1
     },
     list:{
        type:Array,
        default:1
     }
   },
   data() {
     return {
    
     };
   },
   computed: {
    i18n() {
      return this.$t("message");
    },
   },
   watch: {},
   methods: {
    //  不再提示
    promptBtn(item,index){
      if(this.type == 2){
        this.$postRequest("user/upUserRead",{
            table:'trends_user_like',
            id:item.id
        }).then((res) => {
            this.list[index].is_read = 1;
        });
      }else if(this.type == 3){
        this.$postRequest("user/upUserRead",{
            table:'trends_mag',
            id:item.id
        }).then((res) => {
            this.list[index].is_read = 1;
        });
      }
    }
   },
   beforeCreate() {}, //生命周期 - 创建之前
   created() {},
   beforeMount() {}, //生命周期 - 挂载之前
   mounted() {
     
   },
   beforeUpdate() {}, //生命周期 - 更新之前
   updated() {}, //生命周期 - 更新之后
   beforeDestroy() {}, //生命周期 - 销毁之前
   destroyed() {}, //生命周期 - 销毁完成
   activated() {}, //如果页面有keep-alive缓存功能，激活
   deactivated() {}, //如果页面有keep-alive缓存功能，非激活
 };
 </script>
 <style lang="less" scoped>
  .aboutList{
    .list{
        width: 566px;
        height: 111px;
        border-radius: 5px 10px 10px 10px;
        background: #F4F4F4;
        padding: 0 20px 0 14px;
        box-sizing: border-box;
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .listLeft{
            .userInfo{
                display: flex;
                align-items: center;
                .headPic{
                    width: 60px;
                    height: 60px;
                    border-radius: 53.5px;
                    overflow: hidden;
                    border: 2px solid #A3A8AA;
                    img{
                        width: 60px;
                        height: 60px;   
                        object-fit: cover;
                    }
                }
                .userName{
                    color: #333333;
                    font-size: 18px;
                    margin-left: 9px;
                    .time{
                        margin-top: 5px;
                        color: #999999;
                        font-size: 14px;
                    }
                }
            }
            .content{
                margin-top: 7px;
                color: #333333;
                font-size: 14px;
                display: flex;
                align-items: center;
                .con{
                    max-width: 240px;
                }
            }
        }
        .tipsBtn{
            flex-shrink: 0;
            width: 86px;
            height: 36px;
            border-radius: 36px;
            font-size: 14px;
            color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
  }
 </style>