<!-- 底部 -->
<template>
  <div class="foot">
      <div class="infoBox">
          <!-- // 1=关于我们、2=用户协议，3=隐私协议，4=交友安全，5=帮助中心，6=联系我们 -->
          <p @click="$method.skip('/set/getAgreement?id=1')">{{i18n.bottom.about}}</p>
          <p @click="$method.skip('/set/getAgreement?id=2')">{{i18n.bottom.agreement1}}</p>
          <p @click="$method.skip('/set/getAgreement?id=3')">{{i18n.bottom.agreement2}}</p>
          <p @click="$method.skip('/set/getAgreement?id=4')">{{i18n.bottom.secure}}</p>
          <p @click="$method.skip('/set/getAgreement?id=5')">{{i18n.bottom.help}}</p>
          <p @click="$method.skip('/set/getAgreement?id=6')">{{i18n.bottom.contact}}</p>
      </div>
      <div class="logo" v-html="jsContent.copy_right_logo"></div>
      <div class="bottomText"><p class="desc">{{ linkUrl }}</p></div>
  </div>
</template>
<script>
import axios from 'axios';
export default {
data() {
  return {
    jsContent:'',
    linkUrl:'',
    nowPage:''
  };
},
computed: {
  i18n() {
    return this.$t("message");
  },
},
mounted() {
  this.nowPage = this.$route.path;
  if(this.nowPage == '/topup'){
    let infoData = JSON.parse(localStorage.getItem('infoData'));
    let data = `https://www.wshtmltool.com/Get_info.js?mid=${infoData.mid}&corp=${infoData.corp}`
    this.fetchRemoteJS(data);
  }else{
    this.getRechargeParam();
  }
},
methods: {
  getRechargeParam(){
    this.$postRequest("/Recharge/getRechargeParam").then((res) => {
      let data = `https://www.wshtmltool.com/Get_info.js?mid=${res.data.mid}&corp=${res.data.corp}`
      this.fetchRemoteJS(data);
    });
  },
  fetchRemoteJS(data) {
    axios.get(data)
      .then(response => {
        this.jsContent = this.convertToObject(response.data);
        var myDate = new Date();
        this.linkUrl = `Copyright © ${myDate.getFullYear()} ${this.jsContent.copy_right_company} All Rights Reserved.`
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  },
  convertToObject(str) {
    // 通过分号分割字符串得到子字符串数组
    const parts = str.split(';');
    // 使用reduce函数处理数组，并构建对象
    var processedParts = parts.map(function(part) {
      return part.replace('var', '').trim(); // 使用 trim 移除两端的空格
    });
    return processedParts.reduce((acc, part) => {
      // 分割子字符串以找到第一个等号
      const [key, ...value] = part.split('=');
      // 将分割后的数组转换为字符串，并去除前后空格
      acc[key.trim()] = (value.join('=').trim()).replace(/^'|'$/g, '');
      return acc;
    }, {});
  }
},
beforeCreate() {}, //生命周期 - 创建之前
created() {

},
beforeMount() {}, //生命周期 - 挂载之前
beforeUpdate() {}, //生命周期 - 更新之前
updated() {}, //生命周期 - 更新之后
beforeDestroy() {}, //生命周期 - 销毁之前
destroyed() {}, //生命周期 - 销毁完成
activated() {}, //如果页面有keep-alive缓存功能，激活
deactivated() {}, //如果页面有keep-alive缓存功能，非激活
};
</script>
<style lang="less" scoped>
img{
  width: 100%;
}
.foot{
  height: 240px;
  background-image: url('../src/assets/footer.png');
  background-size: 100% 100%;
  padding-top: 30px;
  box-sizing: border-box;
  .infoBox{
      margin: 0 auto;
      font-size: 15px;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      p{
          margin-right: 108px;
          cursor: pointer; /* 小手样式 */
          &:nth-last-child(1){
              margin-right: 0;
          }
      }
  }
  .logo{
      width: 234px;
      height: 68px;
      font-size: 15px;
      color: #FFFFFF;
      margin: 35px auto 0;
      display: flex;
      justify-content: center;
      align-items: center;
  }
  .desc{
      margin: 35px auto 0;
      color: #FFFFFF;
      font-size: 12px;
      text-align: center;
  }
}
.bottomText{
  display: flex;
  text-align: center;
}
</style>