module.exports = {
	message: {
		bottom: {
			language: "EN",
			about: "우리 자신에 대해",
			agreement1: "사용자 협의",
			agreement2: "프라이버시 규약",
			secure: "교우가 안전하다",
			help: "도우미 센터",
			contact: "연락하기",
		},
		navigation: {
			language: "EN",
			home: "첫 페이지",
			post: "글",
			gift: "선물",
			upgrades: "업그레이드",
			topup: "충전",
			homepage: "내 홈페이지",
			set: "개인 설정",
			invite: "친한 친구를 초대하다",
			logout: "로그아웃",
			nologged: "로그인하지 않음",
			search: "검색",
			input: '사용자 이름을 입력하십시오'
		},
		login: {
			welcome: "사용을 환영합니다",
			introduce: "소개",
			welcomelogin: "로그인을 환영합니다",
			nohave: "아직 계좌번호가 없으세요?",
			register: "등록하러 가다",
			username: "메일박스 또는 사용자 이름을 입력하십시오",
			password: "비밀번호를 입력하십시오",
			remember: "비밀번호 기억하기",
			forgot: "비밀번호를 잊어버리다",
			login: "로그인",
		},
		signin: {
			fillreset: "등록 정보를 기입하다",
			upload: "아바타 업로드",
			information: "기본 정보를 기입하기",
			email: "메일 박스",
			email2: "자주 사용하는 편지함을 입력하십시오",
			nickname: "닉네임",
			name: '닉네임 (2-8자 제한)을 입력하십시오',
			setpassword: "비밀번호 설정",
			password: "비밀번호 (대소문자 구별, 6-15자)를 입력하십시오",
			confirmpassword: "비밀번호 확인",
			nextpassword: "비밀번호를 다시 입력하십시오",
			pwd: "암호가 일치하지 않습니다",
			back: "돌아가",
			laststep: "이전 단계",
			next: "다음 단계",
			photo: "아바타 올려주세요",
			sex: "성별",
			Boy: "남자",
			schoolgirl: "여학생",
			localarea: "소재 지역",
			nation: "국가",
			province: "성",
			city: "시",
			oneself: "자기 소개",
			yourself: "자신을 소개해 주세요",
		},
		reset: {
			reset: "비밀번호 초기화",
			email: "메일 박스",
			email2: "자주 사용하는 편지함을 입력하십시오",
			setpassword: "비밀번호 설정",
			password: "비밀번호 (대소문자 구분, 6-15자)를 입력하십시오",
			confirmpassword: "비밀번호 확인",
			nextpassword: "비밀번호를 다시 입력하십시오",
			OK: "확정",
		},
		pop: {
			service: "콜",
			send: "보내기",
			inputtext: "텍스트 입력",
			online: "온라인",
			Offline: "오프 라인",
			profile: "개인 소개",
			cleartext: "대화 기록을 확실히 비우시겠습니까?",
			received: "칭찬을 받다",
			liked: "좋아요",
			lv: "등급",
			EXP: "경험치",
			share: "나누기",
			forward: "보내는 곳:전달",
			website: "보내는 곳:웹사이트",
			picture: "사진",
			photo: "앨범",
			dynamics: "ta의 동태",
			TRANSLATORS: "번역",
			forward2: "이 글을 전재하시겠습니까?",
			delete: "이 게시물을 삭제하시겠습니까?",
			delete2: "이 그림을 삭제하시겠습니까?",
			send2: "댓글을 달아주세요",
			from: "온",
			tag: "태그",
			personal: "개인 정보",
			emote: "표정",
			video: "동영상",
			TA: "TA",
			untranslate: "번역하지 않음",
			text1: "불량정보란 음란물, 폭력, 광고 또는 기타 정상적인 직장 생활을 괴롭히는 내용을 포함하는 것을 말합니다",
			text2: "제보할 정보:",
			text3: "고발하고 싶은 이유를 입력하세요:",
			text4: "마음 놓고 기입하세요, 프라이버시는 저희가 보호해 드리겠습니다",
			fans: "나의 팬",
		},
		btn: {
			chat: "채팅",
			clear: "비우기",
			verify: "확인",
			cancel: "취소",
			clearCon: "내용 비우기",
			prompt: "다시 묻지 않기",
			uncheck: "관을",
			close: "닫기",
			report: "고발",
			publish: "발표",
			add: "추가",
			delete: "삭제",
			upload: '업로드',
			submit: '제출'
		},
		home: {
			vermicelli: "팬",
			dynamic: "동태",
			collect: "소장",
			message: "개인 이야기",
			visitor: "방문 기록",
			service: "고객 서비스 연결",
			system: "시스템 메시지",
			follow: "주목",
			like: "좋아요",
			me: "@나는",
			sendPost: "초대장을 보내다",
		},
		index: {
			all: "전부",
			recommended: "추천",
			send: "보내TA",
			aite: "@TA",
			direct: "개인 이야기",
		},
		post: {
			emote: "표정",
			video: "동영상",
			picture: "사진",
			ta: "TA",
			untranslate: "번역하지 않음",
		},
		gift: {
			total: "모든 선물",
			mygift: "나의 선물",
			all: "전부",
			virtual: "가상 선물",
			accessory: "액세서리",
			doll: "인형",
			flower: "꽃",
			watch: "시계",
			details: "자세 한 정보",
			inventory: "재고",
			specification: "규격",
			buy: "구매",
			text: "인사말을 입력해 주세요",
			quantity: "수량",
			anonymous: "익명으로 증정하다",
			open: "공개적으로 증정하다",
			private: "私下赠送",
			desc: "간단 한 소개"
		},
		upgrades: {
			membership: "승급 회원",
			section: "부분적 기능",
			full: "모든 기능",
			text: "당신은 이미 영구 회원입니다!",
			successfully: "업그레이드 성공~",
			text2: "금화가 부족하면 먼저 충전하세요~",
			text3: "업그레이드 회원 확정되셨나요?",
			text4: "충전을 나가다",
			permanent: "영구",
		},
		topup: {
			coin: "금화를 충전하다",
			amount: "금액",
			other: "기타 금액",
			information: "충전 정보",
			text: "다른 금액을 입력해주세요",
			text2: "충전 기록",
			serial: "일련번호",
			payee: "수금자",
			recharge: "충전 방식",
			time: " 시간",
			status: "상태",
		},
		setting: {
			information: "기본 정보",
			translation: "번역 패키지를 구매하다",
			privacy: "프라이버시 설정",
			notification: "통지",
			blacklist: "사용자 블랙리스트",
			security: "계정 안전",
			invitation: "이메일 초대장",
			invitation2: "링크 초대장",
			avatar: "얼굴을",
			loginemail: "로그인 메일박스",
			areaname: "지역명",
			addtag: "라벨 추가",
			unmodifiable: "수정 불가",
			package: "번역 패키지",
			remaining: "잉여",
			character: "문자",
			text1: "번역 패키지 선택 (문자)",
			text2: "누가 나의 게시물에 대해 평론할 수 있습니까",
			text3: "누가 내 댓글에'좋아요'를 찍어 줄 수 있나요",
			text4: "누가 나의 게시물을 보관할 수 있습니까",
			text5: "모든 사람 (블랙리스트 사용자 제외)",
			text6: "제가 관심을 가지고 있는 사람들",
			text7: "시스템 알림메일 받기:@, 주석, 개인 알림을 받았을 때 시스템에서 보낸 알림메일의 수신 여부와 주기를 설정한다",
			alluding: "내 것에 대해서",
			review: "논평",
			letter: "개인 편지",
			reminder: "이메일 알림",
			receive: "시스템 우편물 수신",
			receive2: "시스템 메시지를 받지 않았습니다",
			never: "받지 않음",
			daily: "일일 접수",
			weekly: "매주 접수",
			monthly: "월간 접수",
			shift: "이동을",
			text8: "블랙리스트에 오른 사용자는 귀하를 보거나, 귀하를 댓글로 보거나, 귀하의 개인 공간을 볼 수 없습니다.이미 팔로우하고 있다면 그 관계는 자동으로 해소된다.",
			addblacklist: "블랙리스트 추가",
			changepassword: "비밀번호 변경",
			bound: "바인딩된 편지함",
			original: "원본 암호",
			newpwd: "새 비밀번호",
			text9: "당신은 메일로 친구를 초대할 수 있습니다. 친구 가입에 성공한 후, 50의 경험치를 획득합니다",
			confirm: "초대장 확인",
			invitation3: "초대 목록",
			text10: "편지함을 입력하십시오"
		},
		othor: {
			online: "온라인",
			Offline: "오프 라인",
			gift: '선물',
			giftHer: '증정하다',
			immediately: '바로 증정',
			Allread: '모두 읽음',
			unread: '읽지 않은',
			read: '읽은',
			text1: '여러분에게 알려줄 어떤 신기한 일이 있습니까~',
			input: '입력하십시오',
			trend: '동태의 자세한 상황',
			system: '소식',
			myname: '나의',
			text: '내 금화',
			btnall: '상용 기능',
			save: '저장',
			mytrends: '나의 움직임',
			mysave: '나의 수집품',
			mylove: '제가 관심을 가지는 것은',
			member: '회원',
			record: '충전 기록',
			Purchase: '구매 기록',
			coin: '금화를 선물하다',
			Buynow: '바로 구매',
			Invitefriends: '친한 친구를 초대하다',
			Copylink: '링크 복사',
			completed: '완료',
			Blacklist: '블랙리스트',
			text2: '금액 선택해주세요',
			text3: '충전 정보를 보완하세요',
			text4: '심의를 기다리다',
			text5: '각하',
			Liked: '좋아요',
			published: '배포됨',
			my: '나는',
			my2: '나는',
			text6: '링크 복사',
			text7: '회원들은 오직 네가 가져가길 기다린다',
			text8: '선물 쇼핑몰',
			text9: '빨리 좋은 친구에게 줄 선물을 고르세요',
			text10: '수신자',
			mount: '총',
			type: '유형',
			money: '충전',
			gomoney: '지금 충전',
			number: '기타 수량',
			realname: '실명',
			inputName: '실명을 입력해 주십시오',
			realnum: '리얼 핸드폰 번호',
			inputNum: '실제 핸드폰 번호를 입력하세요',
			realEmial: '실제 메일박스',
			inputEmial: '실제 편지함을 입력하십시오',
			butText: '구매 정보',
			login1: '같은 세상에서 만나다',
			and: '와',
			you: '네',
			sigin:'지금 등록',
			read2:'나는 이미 읽었고 동의했다',
			login2:'세계 반대편에서 말이죠',
			sigintit:'등록',
			login3:'알',
			login4:'여기에',
			login5:'계좌번호를 가지고 있습니다',
			loginbtn:'지금 로그인',
			end:'완료',
			update:'수정 확인',
			memberdesc:'현재 당신은',
			userinfo:'개인 정보',
			userDesc:'개인 프로필을 입력하세요~',
			chatTit:'대화 중',
			chatTitlist:'채팅 기록',
			tishi:'유효한 내용을 입력하십시오',
			sendText:'증정 기록',
			sendper:'증정인을 입력해주세요',
			buyText:'구매 기록에서 확인하실 수 있어요',
			buySucc:'구매에 성공하다',
			select:'선택',
			selected:'선택 된',
			page:'장',
			agreeBtn:'동의서를 체크하세요',
			senduser:'사용자 증정',
			code:'인증번호를 입력해주세요',
			getCode:'인증 코드',
			sosoText:'성공적으로 발표되었습니다. 심의를 기다리십시오',
			succTrends:'전달 성공',
			total :'도합',
			article:'개',
			getmore:'더 보기',
			usdt:"제출되었습니다. 검토를 기다리십시오",
			upload:"지불 인증서를 업로드합니다",
			address:'주소',
			chinaText:'중국어 입력 안 함',
			delText:'삭제를 하면 쌍방의 대화 기록이 삭제될 수 있다'
		}
	}
};