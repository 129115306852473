<template>
    <el-dialog class="popbigBox" width="400px" :modal="false" :show-close="false" :visible.sync="popDirect">
        <div class="popBox">
            <div class="title">
                <div class="icon" v-if="type != 1">
                    <img v-if="type == 2" src="../src/assets/icon/41.png" alt="">
                    <img v-if="type == 3 || type == 4" src="../src/assets/icon/63.png" alt="">
                </div>
                <p class="tit" :style="{'color':color}">{{titName}}</p>
            </div>
            <div class="btn bgcolor" v-if="type == 4">{{i18n.upgrades.text4}}</div>
        </div> 
    </el-dialog> 
</template>
<script>
export default {
    props:{
        titName:{
            type:String,
            default:''
        },
        color:{
            type:String,
            default:'#333333'
        },
        type:{
            type:Number,
            default:1
        }
    },
  data() {
    //这里存放数据
    return {
        popDirect:false
    };
  },
  computed: {
    i18n() {
      return this.$t("message");
    },
  },
  watch: {},
  methods: {
    popBtn(){ 

    }
  },
  beforeCreate() {}, //生命周期 - 创建之前
  created() {
  },
  beforeMount() {}, //生命周期 - 挂载之前
  mounted() {
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {
  }, //如果页面有keep-alive缓存功能，激活
  deactivated() {}, //如果页面有keep-alive缓存功能，非激活
};
</script>

<style lang="less" scoped>
.popbigBox{
    // ::v-deep .el-dialog{
    //     box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
    // }
}
.popBox{
    width: 400px;
    border-radius: 10px;
    background: #FFFFFF;
    position: relative;
    box-sizing: border-box;
    padding: 24px 50px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
    .title{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        .icon{
            width: 30px;
            height: 30px;
            margin-right: 15px;
            img{
                width: 30px;
                height: 30px;
            }
        }
    }
    .btn{
        margin: 30px auto 0;
        font-size: 16px;
        color: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 242px;
        height: 52px;
        border-radius: 26px;
    }
}
::v-deep .el-dialog{
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
}
</style>